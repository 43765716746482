.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.center-panel-size {
  width: 340px;
  height: 340px;
}
