.map-layer-button {
  cursor: pointer;
  width: 350px;
  height: 80px;

  margin: 24px 0;

  background: var(--white);
  border: 1px solid var(--grey-3);
  border-radius: 4px;

  flex-flow: row nowrap;
}

.map-layer-button-sample {
  width: 64px;
  height: 64px;
  margin: 6px 1px;
  border-radius: 3px;
}

.map-layer-button-caption {
  flex-flow: column nowrap;
  margin: 0 16px;
  width: 100%;
}

.map-layer-button-index {
  display: block;
  text-align: left;
  margin-top: 14px;

  font-family: Inter, sans-serif;
  font-weight: 450;
  font-size: 14px;
  color: var(--grey-5);
}

.map-layer-button-name {
  display: block;
  text-align: left;
  margin-top: 12px;

  font-family: Inter, sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: var(--grey-9);
}

.map-layer-button:hover:not(.map-layer-button--pressed) {
  border: 1px solid var(--grey-5);
}

.map-layer-button--pressed {
  cursor: default;
  border: 1.5px solid #006EC4;
}

.map-layer-button-choice-group {
  margin-top: 16px;
  margin-right: 12px;
}
