.graph-header {
  display: flex;
  vertical-align: middle;
  flex-flow: row nowrap;
  flex: 0;
  height: 64px;
  padding: 8px;

  font-family: Inter, sans-serif;
  font-weight: 600;
  font-size: 18px;

  position: relative;
  z-index: 1000;
}

.graph-body {
  height: calc(100% - 80px);
  width: 100%;
  vertical-align: middle;
}

.graph-canvas {
  max-height: 100%;
}

.graph-field {
  min-width: 400px;
}
