.item-search {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
}
.item-search .info-search {
  overflow-x: clip;
  text-overflow: ellipsis
}
.item-search .highlight {
  color: var(--CTA)
}

.item-search:not(:hover) svg {
  display: none;
}

.item-search svg {
  flex-shrink: 0;
}
.item-search:hover svg {
  color: var(--CTA)
}

.section-title {
  padding: 12px 24px;
  border-top: 1px solid var(--grey-2);
  background: var(--grey-1);
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 650;
  font-size: 13px;
  line-height: 24px;
  text-transform: uppercase;
  color: var(--grey-6);
  font-variation-settings: 'slnt' 0;
}

.dl-search .ms-DetailsRow {
  border-bottom: 1px solid var(--grey-2);
}

#searchForm .ms-TextField-wrapper {
  margin: 10px 0 0 0;
}