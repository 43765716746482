.capex-checkbox.ms-Checkbox {
  height: 24px;
  margin-bottom: 16px;
}

.capex-checkbox.ms-Checkbox .ms-Checkbox-checkmark {
  font-weight: 900;
}

.capex-checkbox.ms-Checkbox .ms-Checkbox-checkbox {
  border: 1.5px solid var(--grey-5);
  background: var(--white);
  border-radius: 4px;
}

.capex-checkbox.ms-Checkbox span.ms-Checkbox-text {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: var(--grey-9);
}

.capex-checkbox.ms-Checkbox:hover:not(.is-disabled) .ms-Checkbox-checkbox {
  background: var(--grey-1);
}

.capex-checkbox.ms-Checkbox.is-checked:hover:not(.is-disabled) .ms-Checkbox-checkbox {
  background: var(--CTA-secondary-pressed);
}

.capex-checkbox.ms-Checkbox:hover:not(.is-checked):not(.is-disabled) .ms-Checkbox-checkmark {
  color: var(--grey-4);
}

.capex-checkbox.ms-Checkbox.is-checked .ms-Checkbox-checkbox {
  border: 1.5px solid var(--CTA);
}

.capex-checkbox.ms-Checkbox.is-checked .ms-Checkbox-checkmark {
  font-weight: 600;
  color: var(--CTA);
}

.capex-checkbox.ms-Checkbox.is-disabled .ms-Checkbox-checkbox {
  border: 1.5px solid var(--grey-3);
}

.capex-checkbox.ms-Checkbox.is-disabled i.ms-Checkbox-checkmark {
  color: var(--grey-3);
}
