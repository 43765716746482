.create-business-case {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.create-business-case .body {
  margin: 16px 16px 0 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.create-business-case .table {
  margin-top: 16px;
  flex: 1;
}
.create-business-case .table .full-height-wrap {
  height: calc(100% - 16px);
}
.create-business-case .footer {
  border-top: 1px solid var(--grey-3);
  padding: 16px;
  margin: 0;
  width: calc(100% - 32px);
}
.statistics-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 350px;
  height: 215px;
}
.statistics-no-data {
  min-width: 350px;
  margin: 40px 0 40px 0;
  text-align: center;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1001;
}