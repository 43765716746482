.modal-content-select-period {
  padding: 0 16px 16px 16px;
}

.dialog-button-select-period {
  width: 150px;
  margin: 0 8px;
}

.modal-content-select-period .scapex-editfield-label {
  top: -14px;
}

.modal-content-select-period .current-period {
  display: flex;
  gap: 10px;
  align-items: center;
}
