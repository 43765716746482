.css-icon {

}

.gps_ring {
    border: 4px solid #b00bde;
    -webkit-border-radius: 30px;
    height: 24px;
    width: 24px;
    -webkit-animation: pulsate 2s ease-out;
    -webkit-animation-iteration-count: infinite;
    /*opacity: 0.0*/
}

@-webkit-keyframes pulsate {
    0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.5;}
    50% {opacity: 1.0;}
    100% {-webkit-transform: scale(1, 1); opacity: 0.0;}
}
