.header {
  justify-content: space-between;
  min-height: 64px;
  border-bottom: 1px solid var(--grey-3);
  box-shadow: 0 2px 7px rgba(4, 3, 18, 0.06);
  width: 100%;
  display: flex;
}

.header-left {
  align-items: center;
  max-width: 40%;
  flex-grow: 0;
}
