.common-subheader {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 15px;
  line-height: 24px;

  letter-spacing: 0.01em;

  margin: 16px 0;

  color: var(--grey-5);
  font-variation-settings: 'slnt' 0;
}
