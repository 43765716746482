.modal-content-change-password {
  padding: 0 16px 16px 16px;
}

.dialog-button-change-password {
  width: 180px;
  margin: 0 8px;
}

.rule_ok {
  list-style-image: url('check_green.png');
}

.rule_error {
  list-style-image: url('error_red.png');
}
