div.workspace.full-height {
  height: calc(100% - 68px);
}

.item-group-filter {
  background-color: rgba(252, 127, 221, 0.25);
}

.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

.Resizer.horizontal {
  height: 11px;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 6px;
  border-left: 3px solid rgba(255, 255, 255, 0);
  border-right: 3px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 3px solid rgba(0, 0, 0, 0.5);
  border-right: 3px solid rgba(0, 0, 0, 0.5);
}

.progress-footer {
  padding-top: 2px !important;
  padding-left: 4px;
  width: 32px;
}
