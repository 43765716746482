.choice-group-label {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.choice-group-label > i {
  margin-left: 5px;
}

.inlineflex .ms-ChoiceField{
  display: inline-block;
  margin-right: 20px;
 }

 .choice-group-spinner {
  height: 205px;
  display: flex;
  justify-content: center;
 }