.searchFieldContainer {
  margin: 24px;
}

.preferences-label {
  margin-bottom: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 550;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: var(--grey-5);
  font-variation-settings: 'slnt' 0;
}

.replaceField {
  padding-top: 1px;
  margin-top: 10px;
}

.search-button {
  flex: 1;
}