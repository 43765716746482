.attributes {
  border-collapse: collapse;
  border-left: 1px solid grey;
  overflow-y: auto;
  height: 600px;
  thead {
    tr {
      background-color: skyblue;
      position: sticky;
      z-index: 1;
      top: 0;
      th {
        border-right: 1px solid grey;
        position: sticky;
        top: 0;
        height: 2em;
      }
    }
  }
  tbody {
    tr {
      height: 2em;
      td {
        border-right: 1px solid grey;
      }
    }
    tr:nth-child(even) {
      background-color: aliceblue;
    }
  }
}
