.group-filter-control {
  display: flex;
  align-content: center;

  position: absolute;
  right: 24px;
  top: 128px;
  z-index: 9999;

  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 15px;
  line-height: 24px;

  padding: 16px;
  color: var(--grey-7);
  background-color: var(--yellow-additional);
  border-radius: 4px;

  /* Secondary Shadow */
  filter: drop-shadow(0 28px 17px rgba(5, 3, 13, 0.06)) drop-shadow(0 11.6977px 7.10219px rgba(5, 3, 13, 0.0431313)) drop-shadow(0 6.25417px 3.79717px rgba(5, 3, 13, 0.0357664)) drop-shadow(0 3.50603px 2.12866px rgba(5, 3, 13, 0.03)) drop-shadow(0 1.86203px 1.13052px rgba(5, 3, 13, 0.0242336)) drop-shadow(0 0.774832px 0.470434px rgba(5, 3, 13, 0.0168687));
}

.group-filter-control>.capex-icon-button {
  position: unset;
}

.group-filter-control-label {
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 3px;
}
