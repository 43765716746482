.top-nav-button {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  margin: 11px 8px 12px;
  padding: 10px;
  height: 20px;

  color: var(--CTA);
  background: var(--white);
  border-radius: 4px;
}

.top-nav-button > * {
  display: inline-block;
  vertical-align: middle;
}

.top-nav-button label {
  cursor: pointer;

  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 16px;
  user-select: none;
  white-space: nowrap;

  margin-left: 13px;
}

.top-nav-button:hover:not(.top-nav-button--pressed):not(.top-nav-button--disabled),
.top-nav-button:hover:not(.top-nav-button--pressed):not(.top-nav-button--disabled) label {
  color: var(--grey-9);
}

.top-nav-button--pressed:not(.top-nav-button--disabled), .top-nav-button--pressed:not(.top-nav-button--disabled) label {
  cursor: default;

  background: var(--CTA-secondary-pressed);
}

.top-nav-button--disabled, .top-nav-button--disabled label {
  cursor: default;

  color: var(--grey-4);
}
