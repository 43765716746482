.capex-tree-grey-node {
  cursor: pointer;
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  align-items: center;
  padding: 8px 24px;

  width: 100%;
  height: 48px;

  background: var(--grey-1);
}

div:not(:first-child) > .capex-tree-grey-node {
  border-top: 1px solid var(--grey-2);
}

.capex-tree-grey-node-icon {
  display: inline-block;
  color: var(--grey-5);
  flex-grow: 0;
}

.capex-tree-white-node {
  cursor: pointer;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  padding: 12px;
  gap: 8px;

  width: 352px;
  height: 48px;

  background: var(--white);
}

.capex-tree-node {
  display: flex;
  flex-grow: 1;
}

.capex-tree-node-icon {
  display: inline-block;
  color: var(--grey-5);
  margin-right: 12px;
  position: relative;
  top: 5px;
}

.capex-tree-node-label {
  display: inline-block;

  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 24px;

  text-transform: uppercase;
  user-select: none;
  flex-grow: 1;

  color: var(--grey-4);
}

.capex-tree-white-node .capex-tree-node-label {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 450;
  font-size: 15px;
  text-transform: none;

  line-height: 24px;  color: var(--grey-5);
}

.capex-tree-white-node .capex-tree-node--selected .capex-tree-node-label {
  color: var(--grey-9);
}

.capex-tree-node--selected .capex-tree-node-icon {
  color: var(--CTA);
}

.capex-tree-node--selected .capex-tree-node-label {
  color: var(--grey-6);
}

.tree-filter-container {
  flex: 1;
  border: none;
  box-shadow: none;
  padding: 0;
}

.tree-lookup-input {
  height: 96px;
  border-bottom: 1px solid var(--grey-2);
}

.tree-lookup-input input {
  width: 244px;
  height: 48px;
  padding: 0 12px 0 48px !important;
  margin: 24px 16px 24px 24px;

  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 450;
  font-size: 15px;
  line-height: 24px;

  color: var(--grey-6);
  background: var(--white);
  border: 1px solid var(--grey-3);
  border-radius: 4px;
}

.tree-lookup-input input:focus {
  border: 1.5px solid var(--CTA);
}

.tree-lookup-input-icon {
  position: absolute;
  top: 38px;
  left: 38px;
  color: var(--grey-5);
}

.capex-tree-node-suffix {
  display: flex;
}

.capex-tree-grey-node .capex-tree-node-suffix {
  margin-right: 12px;
  margin-top: -10px;
}

/* Не знаю, як інакше обійти цю особливість react-virtualized */
/* TODO: знайти кращий спосіб і прибрати цю "милицю" */
.panel .capex-pivot .hc321 .ReactVirtualized__Grid.ReactVirtualized__List[role="grid"] {
  height: calc(100vh - 321px) !important;
  overflow: auto !important; 
}
.panel .capex-pivot .hc225 .ReactVirtualized__Grid.ReactVirtualized__List[role="grid"] {
  height: calc(100vh - 225px) !important;
}
