.settings-columns {
  margin-top: 20px;
  margin-left: 24px;
}

.groupFlexRow .ms-ChoiceFieldGroup-flexContainer {
  display: flex;
}

.groupFlexRow .ms-ChoiceFieldGroup-flexContainer .ms-ChoiceField:nth-child(n+2) {
  margin-left: 32px;
}

.padding-large {
  padding: 24px;
}

.margin-left-large {
  margin-left: 24px;
}

.margin-left-middle {
  margin-left: 16px;
}

.margin-right-large {
  margin-right: 24px;
}

.margin-g-large {
  margin-left: 24px;
  margin-right: 24px;
}

.columns-field {
  line-height: 48px;
  margin: 0 12px;
  border: 1px solid var(--grey-3);
  background: var(--white);
  border-radius: 4px;
}
.columns-field:hover {
  border: 1px solid var(--grey-5);
}
.columns-field:active {
  border: 1.5px solid var(--CTA);
}
.columns-field .column-name{
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1 1 auto;
}
.columns-field label.name {
  margin-left: 16px;
  color: var(--grey-9);
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 450;
  font-size: 15px;
  line-height: 28px;
  white-space: nowrap; /* Отменяем перенос текста */
  overflow: hidden; /* Обрезаем содержимое */
  text-overflow: ellipsis; /* Многоточие */
}

.columns-field label.group {
  margin-left: 16px;
  color: var(--grey-4);
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 450;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap; /* Отменяем перенос текста */
  overflow: hidden; /* Обрезаем содержимое */
  text-overflow: ellipsis; /* Многоточие */
}

.columns-field .displayed {
  margin-left: 16px;
  cursor: pointer;
  color: var(--CTA);
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;
}
.columns-field .move {
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;
}

.columns-field .move.is-disabled {
  color: var(--grey-3)
}

.columns-full {
  overflow: auto;
}

.columns-body {
  overflow: auto;
}
