.capex-tree-white-node .capex-tree-node-suffix .capex-menu-button {
  top: 0;
}

.zone-item-color {
  padding: 0 8px;
  max-width: 24px;
  min-width: 24px;
  min-height: 32px;
  max-height: 32px;
  margin-left: 8px;
}
