.ms-Breadcrumb {
  margin-top: 0;
}

.ms-Breadcrumb-item, .ms-Breadcrumb-itemLink {
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: var(--CTA);
  padding: 0 16px;
}

.ms-Breadcrumb-itemLink:hover {
  background: var(--white);
  color: var(--CTA-primary-pressed);
}

.capex-dropdown-menu .ms-Dropdown-title {
  border: 0 !important;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: var(--CTA);
}

.capex-dropdown-menu .ms-Dropdown:focus::after {
  border: 0;
  outline: 0;
}

.header-left .capex-badge-item::before {
  top: 50%;
  margin-top: -6px;
  position: absolute;
  left: 3px;
  height: 15px;
  width: 15px;
  content: '';
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml,\
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 44">\
  <g fill="%23d7d0ed" stroke="black">\
  <path d="M16.197 19.5L1.5 5.783 1.5 1.5 38.5 1.5 38.5 5.783 23.803 19.5z"/>\
  <path d="M38,2v3.565L23.606,19h-7.212L2,5.565V2H38 M39,1H1v5l15,14h8L39,6V1L39,1z"/>\
  <path d="M16.5 32.75L16.5 19.5 23.5 19.5 23.5 38z"/>\
  <path d="M23,20v17l-6-4.5V20H23 M24,19h-8v14l8,6V19L24,19z"/>\
    </g>\
  </svg>');
}

.breadcrumbContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}