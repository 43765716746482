/* Define variables for theme colors */
:root {
  --CTA: #006EC4;
  --CTA-primary-hover: #1E8CE2;
  --CTA-primary-pressed: #42A4FF;
  --CTA-secondary-pressed: #ECF8FF;

  --yellow-additional: #FFE600;
  --green-additional: #97FF80B5;
  --active-additional: #e1a8ff;
  --active-grey-additional: #69537a;

  --grey-9: #070D1F;
  --grey-8: #333743;
  --grey-7: #424651;
  --grey-6: #5A5D66;
  --grey-5: #6C6F78;
  --grey-4: #9EA0A7;
  --grey-3: #CBCDD6;
  --grey-2: #E2E2E4;
  --grey-1: #F8F8FA;
  --white: #FFFFFF;

  --success: #068849;
  --success-background: #F3F9F6;
  --warning: #B06206;
  --warning-background: #FBF7F3;
  --error: #C70000;
  --error-background: #FCF2F2;
  --info: #003ec4;
  --info-background: #d2e0fd;

  --sector-1: #7F3DE7;
  --sector-1-background: #F5F3FF;
  --sector-2: #CC25B1;
  --sector-2-background: #FFF4FF;
  --sector-3: #D63B3B;
  --sector-3-background: #FFF7F7;
  --sector-4: #8C6604;
  --sector-4-background: #FDFDE6;
  --sector-5: #27851F;
  --sector-5-background: #EDFFEE;
  --sector-6: #1E6F89;
  --sector-6-background: #F0FDF9;
  --sector-7: #5b851f;
  --sector-7-background: #f5FFEE;
  --sector-8: #50851f;
  --sector-8-background: #f1FFEE;

  --height-tr: 40px;
  --height-context-menu-tr: 48px;
  --drawer-width: 64px;
  --drawer-expanded-width: 248px;

  --required-td: #FFF5F5;

  --ruler-text-size: 10;
  --ruler-label-opacity: 0.8;
  --ruler-intermediate-tooltip-display: inherit;
}
