.input-with-color {
  align-self: flex-end;
  padding: 0;
  max-width: 24px;
  min-width: 24px;
  min-height: 46px;
  max-height: 46px;
  border: none;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  position: absolute;
  right: -12px;
  top: -7px;
}

.ms-TextField-fieldGroup:focus .input-with-color {
  min-height: 45px;
  max-height: 45px;
  top: -6.5px;
}

.capex-text-field-color .ms-TextField-suffix {
  width: 0;
  padding: 0;
}

.capex-text-field-color input,
.right-aligned-text input {
  text-align: right;
}

.fix-label-pos .scapex-editfield-label,
.capex-text-field-color .scapex-editfield-label {
  top: -14px;
}

.capex-text-field-color input.ms-TextField-field {
  max-width: calc(100% - 24px);
}
