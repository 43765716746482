.update-footer:hover {
  background-color: white;
}

.grouped-table .ms-List-cell {
  display: flex;
  flex-direction: column;
}

.ms-StackItem.toContentViewport {
    height: calc(100% - 33px);
}
.ms-StackItem.toContentViewport div.ms-Viewport {
    height: 100%;
}

.value {
    background-color: yellow;
}

.value-warning {
    background-color: lightpink;
}
