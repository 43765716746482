//Body background
$background: #F5F8F9;

//Common
$white: #FFFFFF;
$border: #000;
$transparent: transparent;
$transparent25: #00000025;
$transparent95: #FFFFFF95;
$color-blue: #3366FF; // TODO: remove color

$black100: #F3F4F5;
$black200: #E7E9EA;
$black300: #CFD2D6;
$black400: #9EA6AD;
$black500: #6E7984;
$black600: #455363;
$black700: #253647;
$black800: #0d2032;
$black900: #05111C;

// Secondary
$secondary100: #F7F7FF;
$secondary200: #F0EFFE;
$secondary300: #E0DFFE;
$secondary400: #C1BEFD;
$secondary500: #A29EFC;
$secondary600: #837DFB;
$secondary700: #645DFA;
$secondary800: #433AF2;
$secondary900: #2821CF;

// Danger
$error300: #FC80A5;
$error500: #D64173;
$error600: #AA355C;
$error800: #A00438;

table.locked-header {
	thead {
		position: sticky;
		top: 0;
		z-index: 1;
	}
}

table.filters {
	border-collapse: separate;
	border-spacing: 0;

	tr {
		&.borderBottom {
			border-bottom: 1px solid $border;
			border-right: 1px solid $border;
		}
	}
	th {
		background-color: #f0f0f0;
		color: #222;

		.topUp {
			margin-top: -50%;
		}

		&.Fields {
			vertical-align: middle;
			text-align: center;
			align-content: center;
			padding: 0 4px 0 4px;
			border-right: 1px solid #ccc;
		}

		&.Operation {
			background-color: $black300;
			color: #000;
			border: 1px solid $border;
			text-align: center;
			align-content: center;
			padding: 0 4px 0 4px;
		}

		&.Operation_h {
			vertical-align: middle;
			text-align: center;
			align-content: center;
			padding: 0 4px 0 4px;
			border-left: 1px solid #ccc;
			border-right: 1px solid #ccc;
		}
		&.number_row{
			font-weight: normal;
			vertical-align: middle;
			text-align: center;
			align-content: center;
			padding: 0 4px 0 4px;
			border-left: 1px solid #ccc;
			border-right: 1px solid #ccc;
		}

		&.locked0 {
			text-align: center;
			border-top: 1px solid #ccc;
			border-left: 1px solid #ccc;
		}

		&.locked1 {
			border: 1px solid #ccc;
			border-right: 0;
			padding-bottom: 2px;
		}

		&.Column {
			border-bottom: 1px solid $border;
		}
	}
}

.filter-input{
	position: relative;
	display: flex;
	flex-flow: row nowrap;
	border-radius: 4px;
	border: 1px solid $black300;

	.width120 {
		width: 120px;
	}
	.check-box {
		padding: 0 4px 0 4px;
		flex: 1;
	}

	input {
		font: inherit;
		flex: 1;
		height: 100%;
		padding: 0 4px 0 4px;
		border: none;
		border-radius: 4px;
		background: transparent;
		color: inherit;
		&::placeholder {
			color: $black400;
		}
	}

	input.width100 {
		width: 100px;
	}

	.Input__left {
		flex: 0;
		display: flex;
		padding-left: 4px;
		padding-right: 4px;
		cursor: pointer;
		background-color: $secondary100;
		//position: absolute;

		+ input {
			padding-left: 6px;
		}
	}

	.Input__allign_right {
		text-align: right;
	}

	.Input__suffix {
		text-align: left;
		padding-left: 1px;
		max-width: 3em;
	}

	.Input__right {
		flex: 0;
		display: flex;
		padding-right: 4px;
	}

	&:hover:not(.Input--disabled){
		border-color: $black800;
		color: $black800;
		fill: $black800;
	}
	&:focus-within:not(.Input--disabled){
		border-color: $secondary600;
		color: $black800;
		fill: $black800;
	}

	.Icon {
		height: 22px;
		width: 22px;
	}
}

.Input.Input--disabled{
	opacity: .7;
	background: $background;
}
.Input.Input--error{
	border-color: $error500;
	&:hover, &:focus-within {
		border-color: $error500;
	}
}

// Sizes
.Input.Input--small {
	height: 32px;
	font-size: 13px;

	.Icon {
		height: 20px;
		width: 20px;
	}
}
.Input.Input--medium {
	height: 40px;
}
.Input.Input--large {
	height: 48px;
}

.checkbox-intermediate {

		.ms-Checkbox-checkbox {
			border-color: transparent;
		}
		&:hover, &:focus-within {
			.ms-Checkbox-checkbox {
				border-color: $secondary600;
			}
		}

	.ms-Checkbox-checkbox::after {
		border-radius: unset;
		top: 1px;
		left: 3px;
		width: 12px;
		border-width: 0 0 4px 0;
		border-color: transparent;
	}
}
