:root {
  --details-width: calc(100% - 400px - var(--drawer-width) - 2px);
  --details-expanded-width: calc(100% - 400px - var(--drawer-expanded-width) - 2px);
}

.panel {
  display: flex;
  flex-direction: column;
  min-width: 400px;
  max-width: 400px;
  width: 400px;
  height: 100%;

  border-left: 1px solid var(--grey-3);
  position: relative;
  z-index: 999;
}

.panel:not(.with-details) {
  box-shadow: 0 18px 39px rgba(0, 0, 0, 0.07), 0 5.42647px 11.7574px rgba(0, 0, 0, 0.0456112),
    0 2.25388px 4.88341px rgba(0, 0, 0, 0.035), 0 0.815184px 1.76623px rgba(0, 0, 0, 0.0243888);
}

.panel-header {
  display: flex;
  vertical-align: middle;
  flex-flow: row nowrap;
  flex: 0;
  height: 64px;
  width: 100%;

  justify-content: flex-end;

  font-family: Inter, sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: var(--grey-8);

  border-bottom: 1px solid var(--grey-3);
  box-shadow: 0 2px 7px rgba(4, 3, 18, 0.06);
  position: relative;
  z-index: 1000;
}

.panel-header label {
  width: 100%;
  margin: 10px;
  padding: 10px 8px 8px 8px;
}

.panel-header .top-nav-button {
  width: 20px;
  color: var(--grey-5);
}

.panel-enter {
  width: 0;
}

.panel-enter-active {
  width: 400px;
  min-width: 400px;
  max-width: 400px;
  transition: width 0.3s ease-in-out;
}

.panel-exit {
  width: 400px;
  min-width: 400px;
  max-width: 400px;
}

.panel-exit-active {
  width: 0;
  transition: width 0.3s ease-in-out;
}

.panel .fui-AccordionHeader button {
  box-sizing: border-box;
  height: 48px;
  padding-left: 24px;
  width: 100%;

  font-family: Inter, sans-serif;
  font-weight: 650;
  font-size: 13px;
  color: var(--grey-6);
  background: var(--grey-1);

  text-transform: uppercase;
}

.panel .fui-AccordionHeader button span {
  transform: translateX(-19px);
}

.panel .fui-AccordionPanel {
  flex-flow: column nowrap;
}

.panel .fui-AccordionPanel>* {
  display: flex;
}

.panel .panel-footer {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px;
  gap: 24px;

  width: 100%;
  height: 96px;

  background: var(--white);
  border-top: 1px solid var(--grey-3);

  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.details {
  display: flex;
  flex-direction: column;
  height: 100%;

  border-left: 1px solid var(--grey-3);
  position: relative;
  z-index: 999;
}

.details #panel-details-content {
  flex: 1;
  overflow: auto;
}

.details-caption {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}

.details-enter {
  flex: 0;
}

.details-enter-active {
  width: var(--details-width);
  min-width: var(--details-width);
  max-width: var(--details-width);
  transition: width 0.3s ease-in-out;
}

.drawer--expanded ~ .details-enter-active {
  width: var(--details-expanded-width);
  min-width: var(--details-expanded-width);
  max-width: var(--details-expanded-width);
  transition: width 0.3s ease-in-out;
}

.details-enter-done {
  width: var(--details-width);
  min-width: var(--details-width);
  max-width: var(--details-width);
}

.drawer--expanded ~ .details-enter-done {
  width: var(--details-expanded-width);
  min-width: var(--details-expanded-width);
  max-width: var(--details-expanded-width);
}

.details-exit {
  width: var(--details-width);
  min-width: var(--details-width);
  max-width: var(--details-width);
}

.drawer--expanded ~ .details-exit {
  width: var(--details-expanded-width);
  min-width: var(--details-expanded-width);
  max-width: var(--details-expanded-width);
}

.details-exit-active {
  width: 20px;
  transition: width 0.3s ease-in-out;
}

.details-exit-done {
  flex: 0;
}

.panel-content {
  display: flex;
  flex: 1;
  overflow-y: auto;
}

.panel-details-button:not(:hover) {
  color: var(--CTA) !important;
}

.panel-stack {
  width: 100%;
}

.panel-button-container.footer {
  border-top: 1px solid var(--grey-3);
}

.panel-button-container {
  display: flex;
  gap: 24px;
  padding: 24px;
}

.panel-button-container button {
  flex: 1;
  white-space: nowrap;
}

.panel-tree-container {
  border-top: 1px solid var(--grey-3);
  border-bottom: 1px solid var(--grey-3);
}

.panel-sections-container {
  overflow-y: auto;
  border-bottom: 1px solid var(--grey-3);
}

.panel-flex-grow {
  flex-grow: 1;
}

.additional-panel {
  display: flex;
  flex-flow: column nowrap;

  position: absolute;
  flex-flow: column nowrap;
  max-width: calc(80% - 400px);
  min-width: 400px;
  width: fit-content;
  height: calc(100% - 128px);

  background: var(--white);
  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: var(--grey-3);
  border-radius: 4px 0 0 0;

  z-index: 1000;
  right: 401px;
  bottom: 0;
  box-shadow: -10px -10px 39px rgba(0, 0, 0, 0.07);
}

.additional-panel-header-container {
  display: flex;
  flex-flow: row nowrap;
  flex: 0 0 48px;
  padding: 0 24px;
  align-items: center;
  background-color: var(--grey-1);
  height: 48px;
}

.additional-panel-header {
  flex: 1;

  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 650;
  font-size: 13px;
  line-height: 24px;
  text-transform: uppercase;

  color: var(--grey-6);
  font-variation-settings: 'slnt' 0;
}

.additional-panel-content {
  flex: 1 1 auto;
  padding: 24px;
}

.additional-panel-content .wtHolder {
  overflow-x: hidden;
}
