.capex-pivot {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.capex-pivot .ms-Pivot.ms-Pivot--tabs {
  flex: 0;
  min-height: 64px;
  overflow-x: auto;
  scroll-behavior: smooth;
}

.capex-pivot .ms-Pivot.ms-Pivot--tabs::-webkit-scrollbar {
  display: none;
}

.capex-pivot button.ms-Pivot-link {
  height: 64px;
  padding-left: 16px;
  padding-right: 16px;

  font-family: Inter, sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: var(--grey-5);
  background: var(--white);

  border-bottom: 1px solid var(--grey-3);
}

.full-width-header-1-tab.capex-pivot button.ms-Pivot-link {
  width: 100%;
}

.full-width-header-2-tabs.capex-pivot button.ms-Pivot-link {
  width: 50%;
}

.full-width-header-3-tabs.capex-pivot button.ms-Pivot-link {
  width: 33.3333%;
}

.full-width-header-4-tabs.capex-pivot button.ms-Pivot-link {
  width: 25%;
}

.full-width-header-5-tabs.capex-pivot button.ms-Pivot-link {
  width: 20%;
}

.full-width-header-6-tabs.capex-pivot button.ms-Pivot-link {
  width: 16.6667%;
}

.full-width-header-7-tabs.capex-pivot button.ms-Pivot-link {
  width: 14.2857%;
}

.full-width-header-8-tabs.capex-pivot button.ms-Pivot-link {
  width: 12.5%;
}

.full-width-header-9-tabs.capex-pivot button.ms-Pivot-link {
  width: 11.1111%;
}

.full-width-header-10-tabs.capex-pivot button.ms-Pivot-link {
  width: 10%;
}

.capex-pivot button.ms-Pivot-link.is-selected {
  color: var(--CTA);
  background: var(--white);
  background: var(--CTA-secondary-pressed);
  border-bottom: 2px solid var(--CTA);
}

.capex-pivot button.ms-Pivot-link:hover:not(.is-selected) {
  color: var(--grey-9);
  background: var(--white);
}

.capex-pivot > div[role="tabpanel"] {
  flex: 1;
  display: flex;
  flex-flow: column;
  overflow-y: auto;
}

/* It's for <Pivot.Item> */
.capex-pivot > div[role="tabpanel"] > div {
  flex: 1;
  display: flex;
  flex-flow: column;
  overflow-y: auto;
}
