/* .currentRow { */
/*  background-color: aliceblue !important; */
/* } */

.flexRow{
  display: flex;
  flex-direction: row;
  align-items: center
}

.filterTH {
  display: flex;
  padding-left: 4px;
  background-color: #e3b9ff78;
}

.marginLS {
  margin-left: 16px;
}

.menuItem {
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 15px;
}

td .context-menu-item-icon {
  display: flex;
}
td:not(:hover):not(.htDisabled) .marginLM {
  color: var(--grey-9);
}
td:not(:hover):not(.htDisabled) .context-menu-item-icon {
  color: var(--grey-5);
}

.iconContainer {
  /*display: flex;*/
}

/* .read-only-cell { */
/*  background-color: rgba(255, 255, 128, 0.25) !important; */
/* } */

.align-center {
  text-align: center;
}

.ht-td-date {
  text-align: right;
  white-space: nowrap;
}

.not-my-project {
  background-color: rgba(255, 176, 228, 0.25) !important;
}

/*.is-in-my-project {*/
/*  background-color: rgba(151, 255, 128, 0.71) !important;*/
/*}*/

.hot-table-search-highlight, .hot-table-search-highlight * {
  background-color: #cbffa0 !important;
}

.read-only-cell.hideAutocomplete > .htAutocompleteArrow {
  display: none;
}

.handsontable.htRowHeaders.htColumnHeaders {
  /*border: #ccc solid 1px;*/
}
