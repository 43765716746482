.capex-menu-button {
  cursor: pointer;

  justify-content: center;
  align-items: center;
  padding: 6px;
  gap: 8px;

  position: relative;
  top: 5px;
  left: 2px;

  width: 32px;
  height: 32px;

  color: var(--grey-5);
  background: transparent;

  border-radius: 3px;
  border-width: 0;
}

.capex-menu-button.capex-menu-button--small {
  padding: 3px;
  width: 26px;
  height: 26px;
}

.capex-menu-button:hover:not(.capex-menu-button--active):not(.capex-menu-button--disabled) {
  color: var(--grey-9);
  background: transparent;
}

.capex-menu-button:active:not(.capex-menu-button--disabled),
.capex-menu-button--active:not(.capex-menu-button--disabled) {
  color: var(--grey-9);
  background: var(--grey-2);
}

.capex-menu-button--disabled {
  cursor: default;
  color: var(--grey-4);
}
