.search .ms-TextField-prefix {
  background-color: transparent;
  padding-right: 4px;
  padding-left: 12px;
}

.search.ms-TextField .ms-TextField-fieldGroup {
  height: 48px;
  background: var(--white);
  border: 1px solid var(--grey-3);
  border-radius: 4px;
}
.search.ms-TextField .ms-TextField-fieldGroup:hover {
  background: var(--white);
  border: 1px solid var(--grey-5);
  border-radius: 4px;
}

.search.ms-TextField.is-disabled .ms-TextField-fieldGroup {
  background: var(--white);
  border: 1px solid var(--grey-2);
  border-radius: 4px;
}
.search.ms-TextField.is-disabled .ms-TextField-wrapper .ms-Label {
  color: var(--grey-3)
}

.search.ms-TextField .ms-TextField-fieldGroup input.ms-TextField-field {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 450;
  font-size: 15px;
  line-height: 24px;
  color: var(--grey-9)
}
.search.ms-TextField.is-empty .ms-TextField-fieldGroup input.ms-TextField-field {
   color: var(--grey-6)
 }
.search.ms-TextField.is-disabled .ms-TextField-fieldGroup input.ms-TextField-field {
  background: var(--white);
  color: var(--grey-5)
}

.search.ms-TextField.is-active .ms-TextField-fieldGroup3:active {
  background: var(--white);
  border: 1.5px solid var(--CTA);
  border-radius: 4px;
}

.search.ms-TextField.is-active .ms-TextField-fieldGroup::after {
  border: 1.5px solid var(--CTA);
  border-radius: 4px;
}

.search.ms-TextField.is-empty:not(.is-active) .ms-TextField-wrapper .ms-Label {
  display: none;
}

.search.ms-TextField.is-active .ms-TextField-wrapper .ms-Label {
  color: var(--CTA);
}

.search.ms-TextField .ms-TextField-wrapper .ms-Label {
  position: absolute;
  margin-left: 8px;
  margin-top: -12px;
  padding: 0 4px;
  z-index: 1;
  background-color: var(--white);
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: var(--grey-5);
  font-variation-settings: 'slnt' 0;
}

.search .ms-TextField-suffix {
  background-color: transparent;
  border-left: 1px solid;
  border-left-color: var(--grey-3);
  display: flex;
  flex-direction: row;
}

.search .ms-TextField-suffix .capex-icon-button {
  color: var(--grey-3);
  top: 0;
  margin: 5px;
}