.capex-execute-details {
  display: flex;
  flex-direction: row;
}

.capex-execute-details-preview {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.capex-details-tooltip {
  padding-left: 15px;
}

.capex-details-tooltip .react-tooltip-lite {
  min-width: 350px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.tooltip-content {
  display: flex;
  flex-direction: column;
}

.tooltip-content-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.tooltip-content-text {
  margin-top: 10px;
  overflow: auto;
  max-height: 250px;
  max-width: 600px;
}

.scapex-tooltip-arrow {
  position: relative;
}

.scapex-tooltip-arrow-inner {
  position: absolute;
  top: -20px;
  left: -400px;
  height: 40px;
  width: 600px;
  background-color: transparent;
}

.scapex-tooltip-arrow-icon {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 0px;
  height: 0px;
  z-index: 1001;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom-width: 10px;
  border-bottom-style: solid;
  border-bottom-color: white;
}