.select-operation-container {
  padding: 0.5rem;
  flex: 1;
  overflow-y: auto;
}

.select-operation-card {
  margin: 1rem;
  min-width: 60vw;
}

.select-operation-update-db .select-operation-card:first-child {
  min-height: 132px;
}

.ms-DocumentCardTitle.select-operation-title {
  padding-bottom: 0;
}

.ms-DocumentCardTitle.select-operation-description {
  font-family: Inter, sans-serif;
  font-size: 13px;
  padding-top: 0;
  min-height: 64px;
}

.select-operation-update-db .select-operation-card:first-child .select-operation-description {
  min-height: 84px;
}

.select-operation-icon {
  min-height: 100%;
  max-height: 100%;
}

.select-operation-subheader {
  flex: 0;
  margin-left: 24px;
}

.select-operation-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}
