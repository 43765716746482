.capex-toggle .ms-Toggle-background {
  width: 44px;
  height: 24px;
  border-radius: 999px;
  border: 1.5px solid var(--grey-5);
  background: var(--white);
}

.capex-toggle .ms-Toggle-thumb {
  width: 16px;
  height: 16px;
  background: var(--grey-5);
}

.capex-toggle label {
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: var(--grey-9);
}

.capex-toggle .ms-Toggle-innerContainer:hover .ms-Toggle-background {
  background: var(--grey-1);
}

.capex-toggle .ms-Toggle-innerContainer:hover .ms-Toggle-thumb {
  background: var(--grey-4);
}

.capex-toggle.is-checked .ms-Toggle-background {
  border: 1.5px solid var(--CTA);
}

.capex-toggle.is-checked .ms-Toggle-thumb {
  background: var(--CTA);
}

.capex-toggle.is-checked .ms-Toggle-innerContainer:hover .ms-Toggle-thumb {
  background: var(--CTA-primary-hover);
}
