.capex-color-picker-swatch {
  margin: 12px;
}

.capex-color-picker-header {
  border-top: 1px solid var(--grey-2);
}

.capex-color-picker-header > button {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 24px;

  letter-spacing: 0.01em;
  text-transform: uppercase;

  color: var(--grey-5);

  padding: 5px 16px;
}

.capex-color-picker-header > button > span {
  margin-right: 12px;
}

.capex-color-picker-panel {
  margin: 0;
}
