.capex-dropdown-wrapper {
  position: relative;
}

.capex-dropdown-wrapper .ms-Dropdown-container {
  flex: 1;
}

.capex-dropdown {
  position: relative;

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  gap: 12px;
  isolation: isolate;

  height: 48px;

  /* Greyscale Colors/White */
  background: var(--white);
  /* Greyscale Colors/Grey 3 */
  border: 1px solid var(--grey-3);
  border-radius: 4px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  margin: 10px 0;
}

.capex-dropdown .ms-Dropdown {
  flex: 1;
}

.capex-dropdown .ms-Dropdown-title {
  border: 0;
  outline: 0;
}

.capex-dropdown .ms-Dropdown:focus::after {
  border: 0;
  outline: 0;
}

.capex-dropdown .ms-Dropdown {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 450;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 160% */

  /* Greyscale Colors/White */
  background: var(--white);
  /* Greyscale Colors/Grey 9 */
  color: var(--grey-9);
  font-variation-settings: 'slnt' 0;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 1;
  z-index: 1;

  border: 0 !important;
  outline: 0;

  /* Greyscale Colors/White */
  background: var(--white);
}

.capex-dropdown-wrapper:focus-within {
  color: var(--CTA);
}

.capex-dropdown:focus-within {
  border: 1.5px solid var(--CTA);
}

.capex-dropdown-readOnly {
  /* Greyscale Colors/Grey 2 */
  border: 1px solid var(--grey-2);
  color: var(--grey-2);
}

.capex-dropdown-readOnly .ms-TextField-field {
  color: var(--grey-5) !important;
}

.capex-dropdown-wrapper:hover:not(:focus-within) {
  color: var(--grey-5);
}

.capex-dropdown:hover:not(:focus-within) {
  border: 1px solid var(--grey-5);
}


.capex.ms-Dropdown-container {
  position: relative;
  background-color: var(--white);
}

.capex0.ms-Dropdown-container .ms-Dropdown {
  height: 48px;
  background: var(--white);
  border: 1px solid var(--grey-3);
  border-radius: 4px;
}

.capex.ms-Dropdown-container .ms-Dropdown-label.ms-Label {
  position: absolute;
  margin-left: 8px;
  margin-top: -11px;
  padding: 0 4px;
  z-index: 1;
  background-color: var(--white);
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: var(--grey-5);
  font-variation-settings: 'slnt' 0;
}
.capex.ms-Dropdown-container:focus-within .ms-Dropdown-label.ms-Label {
  color: var(--CTA)
}

.capex.ms-Dropdown-container .ms-Dropdown .ms-Dropdown-title {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 450;
  font-size: 15px;
  line-height: 44px;
  color: var(--grey-9);
  font-variation-settings: 'slnt' 0;
  border-radius: 4px;
}
.capex.ms-Dropdown-container .ms-Dropdown:hover .ms-Dropdown-title {
  border-color: var(--grey-5);
}

.capex.ms-Dropdown-container .ms-Dropdown.is-open .ms-Dropdown-title {
  border-width: 1.5px;
  border-color: var(--CTA);
}
.capex.ms-Dropdown-container .ms-Dropdown.is-open:hover .ms-Dropdown-title {
  border-width: 1.5px;
  border-color: var(--CTA);
}

.capex.ms-Dropdown-container .ms-Dropdown:focus::after {
  border-width: 1.5px;
  border-color: var(--CTA);
  border-radius: 4px;
}

div.capex-dropdown-overlay {
  position: relative;
}

.capex-dropdown-overlay .label-overlay {
  position: absolute;
  top: 0;
  margin-top: -11px;
  margin-left: 8px;
  padding: 0 4px;
  white-space: nowrap;
  overflow: hidden;
  max-width: calc(100% - 24px);
  max-width: -webkit-fill-available;
  line-height: 22px;
  background: var(--white);
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 14px;
  letter-spacing: 0.01em;
  color: var(--grey-5);
  font-variation-settings: 'slnt' 0;
}

.capex-dropdown-overlay:focus-within .label-overlay {
  color: var(--CTA)
}
