.capex-choice-group {
  margin-bottom: 20px;
}

.capex-choice-group label::before {
  border: 1px solid var(--grey-5);
}

.capex-choice-group label.is-checked::before {
  border: 1.5px solid var(--CTA);
}

.capex-choice-group label:not(.is-checked):hover::before {
  background-color: var(--grey-1);
  border: 1px solid var(--grey-5);
}

.capex-choice-group label::after {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  border-width: 4px;
  left: 6px;
  top: 6px;
  color: var(--CTA);
}

.capex-choice-group label:not(.is-checked):hover::after {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  border-width: 4px;
  left: 6px;
  top: 6px;
  color: var(--grey-5);
}

.capex-choice-group label span {
  margin-left: 4px;
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: var(--grey-9);
}

.capex-choice-group label.ms-Label {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 15px;
  line-height: 24px;

  letter-spacing: 0.01em;

  color: var(--grey-5);
  font-variation-settings: 'slnt' 0;
}
