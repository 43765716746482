.ci-statistic {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 24px;
}

.ci-statistic tr {
  line-height: 32px;
}

.ci-statistic th, .ci-statistic td {
  padding: 0 12px;
}

.ci-statistic .center {
  text-align: center;
}

.ci-statistic .left {
  text-align: start;
}

.ci-statistic thead th {
  font-weight: 700;
  font-size: 13px;
  color: var(--grey-7);
  background-color: var(--grey-2);
}

.ci-statistic tbody th {
  font-weight: 450;
  color: var(--grey-7);
  background-color: var(--grey-1);
}

.ci-statistic td {
  color: var(--grey-9);
  background-color: var(--white);
}

.ci-bm {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  box-sizing: border-box;
}

.ci-body {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: auto;
  box-sizing: border-box;
}

.select-templates-dropdown {
  max-width: 242px;
}

.table-rate-container {
  max-height: calc(80vh - 144px);
}

.table-rate-container > div {
  display: inline-grid;
}
