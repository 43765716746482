html, body, #root, #root>div, #root>div>div, .full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.full-height>div[role=tabpanel], .full-height-wrap {
  height: calc(100% - 64px);
}

.half-width {
  width: 50%;
}

.small-text {
  font-size: 10px;
  padding-top: 8px !important;
}

.small-pad {
  padding: 4px;
}

.large-pad {
  padding: 24px;
}

.small-pad-lr {
  padding: 0 4px;
}

.medium-pad {
  padding: 8px;
}

.medium-margin {
  margin: 8px;
}

.large-margin {
  margin: 16px;
}

.small-margin-left {
  margin-left: 4px;
}

.small-margin-right {
  margin-right: 4px;
}

.medium-margin-top {
  margin-top: 8px;
}

.margin-topLarge {
  margin-top: 24px;
}
.margin-topM {
  margin-top: 16px;
}
.margin-hLarge {
  margin-left: 24px;
  margin-right: 24px;
}

.modal-cabinet>div.ms-Dialog-main {
  max-width: 100%;
}

.cabinet-panel {
  z-index: 1000;
}

body.waiting * {
  cursor: wait !important;
}

.section {
  border-top: 1px solid var(--grey-2);
  border-bottom: 1px solid var(--grey-2);
}

.flex-uniform {
  flex: 1 1 100%
}

.ms-Dialog-header div[role="heading"] {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 650;
  font-size: 22px;
  line-height: 32px;
}

.ms-Dialog-header, .ms-Modal-scrollableContent > div:first-child {
  border: none;
}

.ms-Dialog-main {
  background: var(--white);
  border: 1px solid var(--grey-2);
  /* Primary Shadow */
  box-shadow: 0 36px 60px rgba(51, 55, 67, 0.13), 0 13.1406px 21.901px rgba(51, 55, 67, 0.0896976), 0 6.37951px 10.6325px rgba(51, 55, 67, 0.0723178), 0 3.12736px 5.21226px rgba(51, 55, 67, 0.0576822), 0 1.23656px 2.06094px rgba(51, 55, 67, 0.0403024);
  border-radius: 8px;
  max-width: unset;
}

.overflow-auto {
  overflow: auto;
}

.scrollable {
  overflow-y: auto;
}

.flex {
  display: flex;
}

label.h2 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--grey-6);
}
