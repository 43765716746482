li.ms-ContextualMenu-item:hover button:not(.is-disabled) .capex-context-menu-item span,
li.ms-ContextualMenu-item button.is-expanded:not(.is-disabled) .capex-context-menu-item span,
li.ms-ContextualMenu-item:hover button:not(.is-disabled) .capex-context-menu-item svg,
li.ms-ContextualMenu-item button.is-expanded:not(.is-disabled) .capex-context-menu-item svg {
  color: var(--CTA);
}

li.ms-ContextualMenu-item:hover button:not(.is-disabled),
li.ms-ContextualMenu-item button.is-expanded:not(.is-disabled) {
  background-color: var(--CTA-secondary-pressed);
  color: var(--CTA);
}

.capex-context-menu-item {
  display: flex;
  flex-flow: row nowrap;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 48px;
}

.capex-context-menu-item span {
  flex-grow: 1;
  margin-left: 8px;
  margin-right: 12px;
  white-space: nowrap;
  color: var(--grey-9);
}

button.is-disabled .capex-context-menu-item span {
  color: var(--grey-4);
}

.capex-context-menu-item svg {
  margin-left: 14px;
  margin-top: 14px;
  margin-right: 8px;
  color: var(--grey-5);
  min-width: 20px;
  max-width: 20px;
}

button.is-disabled .capex-context-menu-item svg {
  color: var(--grey-4);
}

button.ms-ContextualMenu-link {
  height: 48px;
}

.ms-ContextualMenu-Callout {
  box-shadow: unset;
  /* Tertiary Shadow */
  filter: drop-shadow(0 18px 39px rgba(0, 0, 0, 0.07)) drop-shadow(0 5.42647px 11.7574px rgba(0, 0, 0, 0.0456112)) drop-shadow(0 2.25388px 4.88341px rgba(0, 0, 0, 0.035)) drop-shadow(0 0.815184px 1.76623px rgba(0, 0, 0, 0.0243888));
}

.capex-context-menu-item__spacer {
  flex: 0;
  min-width: 22px;
  max-width: 22px;
}
