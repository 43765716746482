.capex-edit-sectors-list-container {
  border: 1px solid rgb(96, 94, 92);
  border-radius: 5px;
  padding: 0px 2px;
}

.capex-edit-sectors-list {
  max-height: 250px;
  overflow-y: auto;
  padding: 10px;
}

.capex-edit-sectors-list .ms-Checkbox:last-child {
  margin-bottom: 0px;
}
