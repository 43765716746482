.capex-spinEditField {
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  gap: 12px;
  isolation: isolate;

  height: 48px;

  /* Greyscale Colors/White */
  background: var(--white);
  /* Greyscale Colors/Grey 3 */
  border: 1px solid var(--grey-3);
  border-radius: 4px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  margin: 10px 0;
}

.capex-spinEditField div {
  flex: 1;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 450;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 160% */

  /* Greyscale Colors/White */
  background: var(--white);
  /* Greyscale Colors/Grey 9 */
  color: var(--grey-9);
}

.capex-spinEditField div::after {
  border: 0 !important;
  outline: 0;
}

.capex-spinEditField:focus-within {
  /* Greyscale Colors/Grey 2 */
  border: 1.5px solid var(--CTA);
  color: var(--CTA);
}

.capex-spinEditField:hover:not(:focus-within) {
  /* Greyscale Colors/Grey 5 */
  border: 1px solid var(--grey-5);
}

.capex-spinEditField-wrapper {
  position: relative;
}

.capex-spinEditField-wrapper:focus-within {
  color: var(--CTA);
}

.capex-spinEditField-wrapper:hover:not(:focus-within) {
  color: var(--grey-5);
}
