.button-container {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin: 0 20px;
}

.button-section-left {
  display: flex;
  flex-flow: row wrap;
  gap: 10px;
}

.button-section-right {
  display: flex;
  gap: 10px;
  align-items: flex-end;
}

.select-tech-neighbors-button {
  height: 100%;
  margin-left: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.select-tech-neighbors-button:hover {
  color: var(--CTA);
}

.select-tech-neighbors-button i {
  margin-right: 5px;
  color: var(--CTA);
}

.select-sector-neighbors-icon {
  height: 0;
}

.capex-neighbors-editor {
  min-height: 400px;
  max-height: 450px;
  overflow: auto;
  overflow-x: hidden;
}

.capex-neighbors-editor-container {
  position: relative;
  margin-bottom: 10px;
}

.capex-neighbors-tech.selected, .capex-neighbors-tech.selected i,
.capex-neighbors-tech.selected .ms-Button-label,
.capex-neighbors-editor-container.selected, .capex-neighbors-editor-container.selected i,
.capex-neighbors-editor-container.selected .ms-Button-label {
  color: var(--CTA);
}

.capex-neighbors-tech.selected .ms-BasePicker-text, .capex-neighbors-editor-container.selected .ms-BasePicker-text {
  border-color: var(--CTA);
}

.capex-neighbors-editor-picker .ms-BasePicker-text {
  padding: 10px;
  border-radius: 5px;
}

.capex-neighbors-editor-label {
  position: absolute;
  top: -8px;
  left: 10px;
  padding: 0px 5px;
  background-color: white;
}

.capex-neighbors-editor-label.on-map {
  top: -5px;
}

.ms-Dialog.scapex-compact .ms-Dialog-inner {
  padding: 0px 4px 4px;
}

.ms-Dialog.scapex-compact .ms-Dialog-subText {
  padding: 0px 10px;
  margin-bottom: 0;
}

.capex-tag-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(243, 242, 241);
  border-radius: 5px;
  padding: 0 0 0 10px;
  margin: 2px;
  width: 104px;
  height: 26px;
}

.capex-tag-item button {
  padding: 0;
}

.capex-tag-item i {
  color: rgb(50, 49, 48);
}

.capex-tag-item.active {
  background-color: var(--CTA-primary-pressed);
}

.button-section-left button.icon-button {
  min-width: 32px;
  width: 32px;
}