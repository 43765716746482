.overflow1 {
	display: flex;
	flex-direction: row;
	flex: 1 1 auto;
	overflow: hidden;

	> .overflow2 {
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
		width: 1px;
		overflow: hidden;

		> .scrollbar {
			flex: 1 1 auto;
			height: 1px;
			overflow: auto;
		}
	}
}

.overflowV {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	> .scrollbar {
		flex: 1 1 auto;
		height: 1px;
		overflow: auto;
		overflow-x: hidden;
	}

	&.noShrink{
		> .scrollbar {
			height: max-content;
			width: max-content;
			max-height: 400px;
		}
	}
}

.overflowH {
	display: flex;
	flex-direction: row;
	overflow: hidden;

	> .scrollbar {
		flex: 1 1 auto;
		width: 1px;
		overflow: auto;
	}
}

.col {
	display: flex;
	flex-direction: column;
	flex: 0 0 auto;
}

.row {
	display: flex;
	flex-direction: row;
	flex: 0 0 auto;
}
