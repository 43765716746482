.bundle-tree-item {
  flex: 1;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 8px 0 8px;
  gap: 8px;
  background: var(--white);
  border: 1px solid var(--grey-3);
  border-radius: 4px;

  height: 68px;
  min-width: 68px;
  max-width: 184px;

  position: relative;
}

.bundle-tree-item.in-tree-current {
  border: 1.5px solid var(--CTA);
  /* Tertiary Shadow */
  box-shadow: 0 18px 39px rgba(0, 0, 0, 0.07), 0 5.42647px 11.7574px rgba(0, 0, 0, 0.0456112), 0 2.25388px 4.88341px rgba(0, 0, 0, 0.035), 0 0.815184px 1.76623px rgba(0, 0, 0, 0.0243888);
}

.bundle-tree-item:hover:not(.is-disabled):not(.in-tree-current) {
  border: 1px solid var(--grey-5);
}

.bundle-tree-item-empty {
  flex: 1;
  height: 68px;
  min-width: 68px;
  max-width: 184px;
  padding: 0 8px 0 8px;
  border: 1px solid transparent;
}

.bundle-tree-item-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;

  min-width: 36px;
  max-width: 36px;
  height: 36px;

  background: var(--grey-1);
  color: var(--grey-5);
  border-radius: 3px;

  order: 0;
}

.bundle-tree-item-name-name-site {
  color: var(--CTA);
}

.bundle-tree-item-icon-site {
  background: var(--CTA-secondary-pressed);
  color: var(--CTA);
}

.bundle-tree-item.GSM900 .bundle-tree-item-icon {
  background: var(--sector-1-background);
  color: var(--sector-1);
}

.bundle-tree-item.GSM1800 .bundle-tree-item-icon {
  background: var(--sector-2-background);
  color: var(--sector-2);
}

.bundle-tree-item.LTE900 .bundle-tree-item-icon {
  background: var(--sector-3-background);
  color: var(--sector-3);
}

.bundle-tree-item.LTE1800 .bundle-tree-item-icon {
  background: var(--sector-4-background);
  color: var(--sector-4);
}

.bundle-tree-item.LTE2100 .bundle-tree-item-icon {
  background: var(--sector-7-background);
  color: var(--sector-7);
}

.bundle-tree-item.LTE2300 .bundle-tree-item-icon {
  background: var(--sector-8-background);
  color: var(--sector-8);
}

.bundle-tree-item.LTE2600 .bundle-tree-item-icon {
  background: var(--sector-5-background);
  color: var(--sector-5);
}

.bundle-tree-item.LTE2100 .bundle-tree-item-icon {
  background: var(--sector-7-background);
  color: var(--sector-7);
}

.bundle-tree-item.LTE2300 .bundle-tree-item-icon {
  background: var(--sector-8-background);
  color: var(--sector-8);
}

.bundle-tree-item.UMTS2100 .bundle-tree-item-icon {
  background: var(--sector-6-background);
  color: var(--sector-6);
}

.bundle-tree-item-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0;
  gap: 2px;

  order: 1;
  flex: 1;

  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 450;
  font-size: 12px;
  line-height: 24px;

  font-variation-settings: 'slnt' 0;

  overflow: hidden;
}

.bundle-tree-item-name label {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: left;
}

.bundle-tree-item-name-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

.bundle-tree-item .delete-button {
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 4px 4px 4px;

  position: absolute;
  width: 20px;
  height: 20px;
  right: -6px;
  top: -6px;

  background: var(--grey-1);
  border: 1px solid var(--grey-5);
  border-radius: 4px;

  /* Inside auto layout */
  flex: none;
  order: 2;
  flex-grow: 0;
  z-index: 2;
}

.bundle-tree-item .delete-button:hover {
  color: var(--error);
}

.bundle-tree-item.in-tree-removed {
  color: #9EA6AD;
  text-decoration: line-through;
}

