.container-trf {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.container-trf-table {
  flex: 1 1 auto;
}

.container-trf .full-height-wrap{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 1 1 auto;
}

#trfTable thead th {
  font-weight:bold;
  border-color: var(--grey-1);
}

#trfTable tbody td, #trfTable tbody th {
  border-color: var(--grey-3);
  font-size: smaller;
}

#trfTable tbody .scapex-trf-actual {
  white-space: nowrap;
}

#trfTable tbody .scapex-trf-forecast {
  background-color: var(--CTA-secondary-pressed);
  white-space: nowrap;
}

#trfTable tbody .scapex-trf-forecast.read-only-cell {
  background-color: #d9e1f2;
}