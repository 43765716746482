.autosave-mode {
  flex: none;
  padding: 2px;

  min-width: 60px;
  min-height: 60px;

  border-top: 1px solid var(--grey-6);
  background: var(--grey-7);
}

.autosave-mode--expanded {
  min-width: 244px;
  min-height: 132px;
}

.autosave-mode--expanded.autosave-mode--active {
  min-height: 68px;
}

.autosave-mode label {
  cursor: pointer;

  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 15px;

  color: var(--grey-2);
}

.autosave-mode label:hover {
  color: var(--white);
}

.autosave-mode--expanded .ms-Toggle {
  margin-left: 22px;
  margin-top: 20px;
}

.autosave-mode--expanded .ms-Toggle-innerContainer {
  margin-left: 22px;
}

.autosave-mode--expanded:not(.autosave-mode--active) .ms-Toggle-background:not(:hover) {
  background: var(--grey-4);
}

.autosave-mode--expanded:not(.autosave-mode--active) .ms-Toggle-thumb {
  background: var(--grey-8);
}

.autosave-mode-button-container {
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  margin-left: 24px;
  gap: 16px;
}
