.side-nav-button {
  cursor: pointer;

  margin: 8px 12px;
  padding: 10px;
  width: calc(100% - 24px);
  height: 40px;

  color: var(--grey-4);
  background: var(--grey-8);
  border-radius: 4px;
}

.side-nav-button label {
  cursor: pointer;

  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 16px;
  margin-left: 16px;

  color: var(--grey-2);
}

.side-nav-button:hover:not(.side-nav-button--pressed):not(.side-nav-button--disabled),
.side-nav-button:hover:not(.side-nav-button--pressed):not(.side-nav-button--disabled) label {
  color: var(--white);
}

.side-nav-button--pressed, .side-nav-button--pressed label {
  cursor: default;

  background: var(--grey-7);
  color: var(--CTA-primary-pressed);
}

.side-nav-button--lighter {
  cursor: pointer;

  background: var(--grey-7);
}

.side-nav-button--disabled, .side-nav-button--disabled label {
  cursor: default;

  color: var(--grey-6);
}

.side-nav-divider {
  width: 24px;
  margin-left: 20px;
  margin-bottom: 8px;
  height: 1px;

  background: var(--grey-6);
}

.side-nav-divider--expanded {
  width: 192px;
  margin-left: 28px;
}

.side-nav-button-label {
  flex-grow: 1;
}

.side-nav-button-close {
  cursor: pointer;
}

.side-nav-button-close:hover {
  color: #ff5050;
}
