#historical-data  .historical-data-content {
  height: calc(100% - 64px);
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

#historical-data .ms-DatePicker {
  min-width: 180px;
  max-width: 180px;
}

#historical-data #historical-data-month-to {
  margin-left: 10px;
}

#historical-data .historical-data-type {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#historical-data .historical-data-format {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#historical-data .historical-data-date-range {
  display: flex;
  flex-direction: row;
}

#historical-data .capex-dropdown-wrapper {
  margin-left: 10px;
  margin-right: 10px;
  min-width: 382px;
  max-width: 382px;
}

#historical-data .historical-data-type .capex-dropdown-wrapper {
  margin-left: 0;
}

#historical-data .historical-data-date-range .capex-dropdown-wrapper {
  min-width: 180px;
  max-width: 180px;
}

#historical-data  .historical-data-table {
  margin-top: 5px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}