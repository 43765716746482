.autosave-mode-button {
  cursor: pointer;

  min-width: 40px;
  min-height: 40px;

  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 16px;

  padding: 0 24px;
  border-radius: 4px;
  border: 0;
  color: var(--white);
  background: var(--CTA);
}

.autosave-mode-button:hover {
  background: var(--CTA-primary-hover);
}

.autosave-mode-button:active {
  background: var(--CTA-primary-pressed);
}

.autosave-mode-button:disabled {
  cursor: default;
  color: var(--grey-4);
  background: var(--grey-5);
}
