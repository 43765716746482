.network-item-bundle-tree {
  flex: 1;
}

.network-item-bundle-tree-row {
  display: flex;
  gap: 5px;
  justify-content: space-around;
  margin: 25px 0;
  padding: 0 5px;
  position: relative;
  min-width: 850px;
}

.network-item-bundle-tree-row button {
  flex: 1;
  padding: 0 8px 0 8px !important;
  max-width: 202px;
}

.network-item-bundle-tree .network-item-line {
  position: absolute;
  width: 200px;
  height: 100px;
  left: 50%;
  top: 50%;

  /* Greyscale Colors/Grey 2 */
  border: 2px dashed var(--grey-2);
  border-bottom: 0;
  border-radius: 8px;
}

.network-item-bundle-tree .network-item-line.network-item-line-1 {
  top: calc(50% - 15px);
  width: calc(100% / 8 * 3.5);
  left: calc(50% - 100% / 8 * 3.5);
  border-right: 0;
}

.network-item-bundle-tree .network-item-line.network-item-line-2 {
  top: calc(50% - 5px);
  width: calc(100% / 8 * 2.5);
  left: calc(50% - 100% / 8 * 2.5);
  border-right: 0;
}

.network-item-bundle-tree .network-item-line.network-item-line-3 {
  top: calc(50% + 5px);
  width: calc(100% / 8 * 1.5);
  left: calc(50% - 100% / 8 * 1.5);
  border-right: 0;
}

.network-item-bundle-tree .network-item-line.network-item-line-4 {
  top: calc(50% + 15px);
  width: calc(100% / 8 * 0.5);
  left: calc(50% - 100% / 8 * 0.5);
  border-right: 0;
}

.network-item-bundle-tree .network-item-line.network-item-line-5 {
  top: calc(50% + 15px);
  width: calc(100% / 8 * 0.5);
  border-left: 0;
}

.network-item-bundle-tree .network-item-line.network-item-line-6 {
  top: calc(50% + 5px);
  width: calc(100% / 8 * 1.5);
  border-left: 0;
}

.network-item-bundle-tree .network-item-line.network-item-line-7 {
  top: calc(50% - 5px);
  width: calc(100% / 8 * 2.5);
  border-left: 0;
}

.network-item-bundle-tree .network-item-line.network-item-line-8 {
  top: calc(50% - 15px);
  width: calc(100% / 8 * 3.5);
  border-left: 0;
}

.network-item-bundle-tree .network-item-line.network-item-line-active {
  /* Main Colors/CTA */
  border-color: var(--CTA);
  border-style: solid;
}
