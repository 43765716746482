.capex-badge {
 position: relative;   
}

.capex-badge-position {
  position: absolute !important;
  top: -6px;
  left: -6px;
}

.capex-badge-position .react-tooltip-lite {
  width: max-content !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.capex-badge-item {
  background-color: var(--error);
  color: var(--white);

  border-radius: 6px;
  width: auto;
  min-width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-tooltip-lite {
  background-color: var(--white);
  border-color: var(--grey-6);
  border-radius: 5px;
  min-width: 300px;
}

.react-tooltip-lite-arrow {
  margin-left: 6px;
  border-color: var(--white);
}

.react-tooltip-lite p {
  white-space: nowrap;
  margin: 7px;
}

.react-tooltip-lite button {
  height: 30px !important;
}

.capex-badge-position .badge-tooltip-arrow {
  position: relative;
}

.capex-badge-position .badge-tooltip-arrow-inner {
  position: absolute;
  top: -20px;
  left: -100px;
  height: 40px;
  width: 400px;
  background-color: transparent;
}

.capex-badge-position .badge-tooltip-arrow-icon {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 0px;
  height: 0px;
  z-index: 1001;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom-width: 10px;
  border-bottom-style: solid;
  border-bottom-color: white;
}
