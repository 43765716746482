.input-spin {
  div {
    &:after {
      border-style: none;
    };
    background-color: transparent;
    span {
      background-color: transparent;
    }
  };
  .ms-Button--icon {
    .ms-Button-flexContainer {
      color: transparent;
    };
  };
  .ms-Button.ms-Button--icon.is-disabled {
    background-color: transparent;
    span {
      i {
        color: transparent;
      }
    }
  };
  &:hover {
    .ms-Button--icon {
      .ms-Button-flexContainer {
        color: inherit;
      };
    };
  };
};

.widthPercent {
    width: 70px;
};

