.bc-summary {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.bc-summary-content {
  height: calc(100% - 97px);
}

.bc-summary-content .ms-Checkbox {
  margin-top: 16px;
}

.bc-summary-footer {
  display: flex;
  flex: 0;
  justify-content: space-evenly;
  padding: 24px 0;
  border-top: 1px solid var(--grey-3);
}

.bc-summary-footer button {
  width: 40%;
}

.caseSummaryFinancials .ht_master > .wtHolder {
  overflow-x: auto;
}
