.info-tooltip {
  position: absolute;
  top: 2px;
  right: 8px;
}

.info-tooltip-callout {
  padding: 10px;
}

.info-tooltip-button:hover {
  background: transparent;
}
