.autosave-mode-icon-button {
  cursor: pointer;

  min-width: 40px;
  min-height: 40px;

  padding: 0 10px;
  border-radius: 4px;
  border: 1px solid var(--grey-3);
  color: var(--grey-3);
  background: var(--grey-7);
}

.autosave-mode-icon-button:hover:not(:disabled) {
  background: var(--grey-6);
}

.autosave-mode-icon-button:active:not(:disabled) {
  border: 1px solid var(--white);
  color: var(--white);
  background: var(--grey-5);
}

.autosave-mode-icon-button:disabled {
  cursor: default;
  color: var(--grey-5);
  border: 1px solid var(--grey-5);
}
