.capex-button.ms-Button {
  height: 48px;
  display: flex inline;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;
  border: 1px solid var(--CTA);
  border-radius: 4px;
}

.capex-button.ms-Button.ms-Button--default,
.capex-button.ms-Button.ms-Button--primary {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 16px;
  font-variation-settings: 'slnt' 0;
  line-height: 24px;
}

.capex-button.ms-Button.ms-Button--primary {
  background: var(--CTA);
  color: var(--white);
}

.capex-button.ms-Button.ms-Button--default {
  background: var(--white);
  border: 1px solid var(--grey-5);
  color: var(--grey-5)
}

.capex-button.ms-Button.ms-Button--secondary {
  background: var(--white);
  border: 1px solid var(--CTA);
  color: var(--CTA)
}

.capex-button.ms-Button.ms-Button--primary:hover:not(.is-disabled) {
  background: var(--CTA-primary-hover);
}

.capex-button.ms-Button.ms-Button--default:hover:not(.is-disabled) {
  border: 1px solid var(--grey-9);
  color: var(--grey-9)
}

.capex-button.ms-Button.ms-Button--default.ms-Button--secondary:hover:not(.is-disabled) {
  border: 1px solid var(--grey-9);
  color: var(--grey-9)
}

.capex-button.ms-Button.ms-Button--primary:active:not(.is-disabled) {
  background: var(--CTA-primary-pressed);
}

.capex-button.ms-Button.ms-Button--default:active:not(.is-disabled) {
  background: var(--grey-1);
  border: 1px solid var(--grey-9);
  color: var(--grey-9);
}

.capex-button.ms-Button.ms-Button--default.ms-Button--secondary:active:not(.is-disabled) {
  background: var(--CTA-secondary-pressed);
  border: 1px solid var(--CTA);
  color: var(--CTA);
}

.capex-button.ms-Button.ms-Button--primary.is-disabled {
  background:  var(--grey-3);
  border: 1px solid var(--grey-3);
}

.capex-button.ms-Button.ms-Button--default.is-disabled {
  border: 1px solid var(--grey-3);
  color: var(--grey-3)
}

.capex-button.ms-Button.ms-Button--default.ms-Button--secondary.is-disabled {
  border: 1px solid var(--grey-3);
  color: var(--grey-3)
}
