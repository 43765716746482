.scapex-editfield-label {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  letter-spacing: 0.01em;

  /* Greyscale Colors/Grey 5 */
  /* color: var(--grey-5); */
  font-variation-settings: 'slnt' 0;

  position: absolute;
  left: 8px;
  top: -4px;

  /* Inside auto layout */
  flex: none;
  order: 3;
  flex-grow: 0;
  z-index: 3;
}

.scapex-editfield-label-stack {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 4px;

  height: 10px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 2px 0;
  z-index: 1;
}

.scapex-editfield-label-stack .ms-Button {
  width: 26px;
  height: 26px;
}

.scapex-editfield-label-stack .ms-Button:hover {
  /* Greyscale Colors/White */
  background: transparent;
}

.scapex-editfield-label-stack span {
  white-space: nowrap;
  overflow: hidden;
}

.scapex-editfield-label-background {
  height: 4px;

  /* Greyscale Colors/White */
  background: var(--white);

  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  z-index: 0;
}
