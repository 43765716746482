.handsontable .ms-Spinner.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.handsontable .ms-Spinner .ms-Spinner-circle.circle {
  box-sizing: border-box;
  border-radius: 50%;
  border-width: 1.5px;
  border-style: solid;
  border-color: var(--CTA) rgb(199, 224, 244) rgb(199, 224, 244);
  border-image: initial;
  animation-name: css-120;
  animation-duration: 1.3s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.53, 0.21, 0.29, 0.67);
  width: 16px;
  height: 16px;
}
