.leaflet-button {
  box-shadow: 0 1px 5px rgba(0,0,0,0.65);
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #ccc;
  width: 33px;
  height: 33px;
  line-height: 33px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: black;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  cursor: pointer;
}

.leaflet-button:hover {
  background-color: #f4f4f4;
}
