.capex-notifications {
  flex: 1 1 auto;
}

.capex-notifications .full-height-wrap {
  height: 100%;
}

.notification__content-size {
  margin-left: 12px;
  color: #888;
  font-size: 10px !important;
}
