.network-item-editor-footer {
    display: flex;
    flex: 0;
    justify-content: space-evenly;
    padding: 24px 0;
    border-top: 1px solid var(--grey-3);
}

.network-item-editor-footer button {
    width: 40%;
}

.network-item-bundle-breadcrumb {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.network-item-editor-content {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.network-item-editor-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
}

.network-item-editor-content .text-field-container {
    flex: 1;
}
