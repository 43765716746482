.accordion-panel {
  padding: 0 12px;
}

.capex-section-stack {

}

.capex-section {
  overflow-y: auto;
  flex: 1;
}

.capex-section-header {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 24px;
  gap: 24px;

  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 650;
  font-size: 13px;
  line-height: 24px;

  text-transform: uppercase;

  color: var(--grey-6);
  font-variation-settings: 'slnt' 0;

  width: 100%;
  height: 48px;

  background: var(--grey-1);

  flex-grow: 0;
}

.capex-section:not(:first-child) > .capex-section-header {
  border-top: 1px solid var(--grey-2);
}

.capex-section-panel {
  padding: 12px 24px;
}

.capex-section-header-title {
  flex-grow: 1;
}

.capex-section-header-suffix {
  flex-grow: 0;
  margin-top: -8px;
}

.fui-AccordionHeader .capex-section-header-suffix>button {
  padding-left: 6px;
  margin-right: 36px;
}
