.snc-error-message > div:first-child {
  color: white;
  background-color: var(--warning);
  padding-top: 1px;
  width: 20px;
  height: 19px;
  border-radius: 50%;
  font-weight: bold;
  text-align: center;
}

.snc-error-message > [role="status"] {
  display: block;
}

.snc-error-message > [role="status"]::first-line {
  font-weight: bold;
}
