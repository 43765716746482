.event-log-content {
  display: flex;
  flex-direction: column;
}

.event-log-content .panel-header {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
}

.event-log-content .panel-header .small-item {
  flex: 0 0 auto;
  margin-left: 12px;
  max-width: 120px;
}

.event-log-content .panel-header .item {
  flex: 0 0 auto;
  margin-left: 12px;
  min-width: 200px;
}

.event-log-content .panel-header .large-item {
  flex: 0 0 auto;
  margin-left: 12px;
  min-width: 280px;
}

.event-log-content .panel-header .tail {
  flex: 1 1 auto;
}

.event-log-content .panel-spinner {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
}

.event-log-content .panel-no-logs {
  flex: 1 1 auto;
  display: flex;
  color: red;
  justify-content: center;
}

.event-log-content .panel-logs {
  flex: 1 1 auto;
  overflow: auto;
  padding: 5px;
  max-height: calc(100vh - 192px);
}

.event-log-content .panel-logs p {
  white-space: pre-line;
}
