.share-project .ms-Dialog-main {
  max-width: 640px;
}
.share-project .ms-PickerPersona-container{
  margin: 8px;
}

.footerSettings{
  padding: 16px 8px;
}

.modal-cabinet>div.ms-Dialog-main {
  max-width: 100%;
}

p.item{
  margin-block-start: 0.25em;
  margin-block-end: 0.25em;
}

p.title{
  font-weight: 600;
  margin-block-end: 0.5em;
}

p.lastItem{
  margin-block-start: 0.25em;
}

.buttons-dialog-footer {
  padding-top: 16px;
}
