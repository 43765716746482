.top-most {
  z-index: 100000;
}

.modal-content-financials {
  padding: 0 16px 16px 16px;
}

.dialog-button-financials {
  width: 120px;
  margin: 0 8px;
}

.financials-select-parameters {
  margin: 0px 0px 10px 10px;
}

.financials-select-parameters .scapex-editfield-label {
  top: -15px;
}

.financials-select-parameters .parameters-spinner {
  min-width: 400px;
  margin: 27px 0;
}

.financials-buttons {
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}