.handsontable td.scapex-business-case-driver-based {
  background-color: var(--CTA-secondary-pressed);
}

.handsontable td.scapex-business-case-driver-based.read-only-cell {
  background-color: #DFECF4;
}

.handsontable td.scapex-business-case-machine-learning {
  background-color: var(--success-background);
}

.handsontable td.scapex-business-case-machine-learning.read-only-cell {
  background-color: #E8EFEB;
}

.handsontable td.scapex-business-case-manual-neighbors {
  background-color: var(--warning-background);
}

.handsontable td.scapex-business-case-manual-neighbors.read-only-cell {
  background-color: #F2EFEB;
}
