.invalid input {
  color: red;
  font-weight: bold;
}

.coordinates-editor textarea {
  max-height: 40vh;
  overflow: auto;
}

.coordinates-editor-circle textarea {
  max-height: 20vh;
  overflow: auto;
}
