.metrics-panel {
  padding: 2px;
  display: flex;

  min-width: 60px;
  min-height: 16px;

  border-top: 1px solid var(--grey-6);
  background: var(--grey-7);

  justify-content: space-between;
}

.metrics-panel--expanded {
  min-width: 244px;
}

.metrics-panel label {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 12px;

  margin-right: 8px;
  color: var(--grey-2);
}

.metrics-panel label:hover {
  color: var(--white);
}

.metrics-panel__indicator {
  width: 9px;
  height: 9px;
  margin: 3px;

  border-radius: 50%;
}

.metrics-panel__indicator--null {
  background: #e4e400;
}

.metrics-panel__indicator--true {
  background: #11c21a;
}

.metrics-panel__indicator--false {
  background: #ff0000;
}

.capex-metric-indicators {
  display: flex;
  flex-direction: row;
}
.capex-metric-indicators .react-tooltip-lite {
  width: 150px !important;
  min-width: 100px !important;
}
.capex-metric-indicators-arrow .react-tooltip-lite:after {
  position: absolute;
  bottom: -6px;
  left: 2px;
  
  border-top: 8px solid var(--white);
  border-right: 30px solid transparent;
  content: "";
}
.capex-metric-indicators .react-tooltip-lite-arrow {
  margin-left: 0;
  margin-top: -1px;
}
.capex-metric-indicators h5 {
  margin: 0;
}
.capex-metric-indicators ul {
  margin: 5px 0 0 0;
  list-style : none;
  padding-left : 0.5rem;
}
.capex-metric-indicators li {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.capex-metric-indicators li span {
  margin-left: 2px;
}
