.drawer {
  display: flex;
  flex-direction: column;
  min-width: var(--drawer-width);
  max-width: var(--drawer-width);
  height: 100%;

  background: var(--grey-8);
}

.drawer--expanded {
  min-width: var(--drawer-expanded-width);
}

.drawer-logo {
  flex: none;
  cursor: pointer;

  padding: 12px 12px 11px 12px;

  min-width: 40px;
  min-height: 40px;
  max-height: 40px;

  border-bottom: 1px solid var(--grey-6);
}

.drawer-logo--expanded .drawer-version {
  display: inherit !important;
  position: absolute;
  color: var(--CTA);
  bottom: 5px;
  right: 10px;
  font-size: 12px;
}

.drawer-logo .drawer-version {
  display: none;
}

.drawer-logo--expanded {
  min-width: 224px;
}

.drawer-container {
  flex-grow: 1;
  overflow-y: auto;
}

.drawer-container::-webkit-scrollbar {
  display: none;
}

.hidden-scrollbar {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.hidden-scrollbar::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}

.scapex-no-pointer-events {
  pointer-events: none;
}
