#map-container {
  background: #fff;
}

.Done-Icon {
  background-image: url(../../../assets/icons/icons8-check-mark-24.png);
}

.Target-Coverage-Icon {
  background-image: url(../../../assets/icons/icons8-check-mark-24.png);
}

.info {
  background: var(--white);
  border-radius: 4px;
  /* Tertiary Shadow */
  filter: drop-shadow(0 18px 39px rgba(0, 0, 0, 0.07)) drop-shadow(0 5.42647px 11.7574px rgba(0, 0, 0, 0.0456112)) drop-shadow(0 2.25388px 4.88341px rgba(0, 0, 0, 0.035)) drop-shadow(0 0.815184px 1.76623px rgba(0, 0, 0, 0.0243888));
}

.info h4 {
  margin: 0;
  padding-left: 36px;

  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 40px;

  color: var(--grey-6);
  background: var(--grey-1);
}

.legend {
  line-height: 18px;
  color: var(--grey-9);
  /* overflow-y: auto; */
  scrollbar-gutter: stable;

  /* max-height: calc(100vh - 160px); */
  max-width: calc(25vw);
  min-width: 15em;

  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.legend > .caption {
  display: flex;
  cursor: pointer;
  padding: 12px 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  background: var(--white);
}

.legend > .caption > .dropdown-icon {
  margin-top: 2px;
  margin-right: 6px;
  color: var(--grey-6);
}

.legend > .caption > span {
  flex-grow: 1;
}

.legend-item {
  padding-right: 16px;
  height: 40px;

  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  background: var(--white);
  border-width: 0 1px 1px 0;
  border-style: solid;
  border-color: var(--grey-2);
}

.legend-item .legend-item-sample {
  width: 24px;
  height: 40px;
  float: left;
  margin-right: 12px;
}

.legend-item .legend-item-label {
  padding-right: 36px;
  padding-top: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.menu-item {
  margin: 4px 8px;
  cursor: pointer;
}

.menu-item:hover {
  background: #efefef
}

.create-site-cursor {
  position: absolute;
  flex-flow: row nowrap;
  z-index: 9999;
  pointer-events: none;
}

.create-site-cursor-icon {
  display: inline-block;
  color: var(--grey-9);
  margin-right: 16px;
}

.create-site-cursor-hint {
  display: inline-block;
  position: relative;
  top: -7px;

  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 16px;
  line-height: 24px;

  color: white;
  padding: 8px 16px;
  background: rgba(51, 55, 67, 0.8);
  /* Secondary Shadow */
  box-shadow: 0 28px 17px rgba(5, 3, 13, 0.06), 0 11.6977px 7.10219px rgba(5, 3, 13, 0.0431313), 0 6.25417px 3.79717px rgba(5, 3, 13, 0.0357664), 0 3.50603px 2.12866px rgba(5, 3, 13, 0.03), 0 1.86203px 1.13052px rgba(5, 3, 13, 0.0242336), 0 0.774832px 0.470434px rgba(5, 3, 13, 0.0168687);
  border-radius: 4px;
}

.leaflet-container.hide-default-cursor {
  cursor: none;
}

.leaflet-target-coverage-button {
  background-image: url(../../../assets/icons/edit-target.png);
}

.leaflet-control-zoom.leaflet-bar.leaflet-control {
  border: 1px solid var(--grey-2);
  border-radius: 4px;
  /* Tertiary Shadow */
  filter: drop-shadow(0 18px 39px rgba(0, 0, 0, 0.07)) drop-shadow(0 5.42647px 11.7574px rgba(0, 0, 0, 0.0456112)) drop-shadow(0 2.25388px 4.88341px rgba(0, 0, 0, 0.035)) drop-shadow(0 0.815184px 1.76623px rgba(0, 0, 0, 0.0243888));
}

a.leaflet-control-zoom-in {
  border-bottom: 1px solid var(--grey-2);
}

a.leaflet-control-zoom-in, a.leaflet-control-zoom-out {
  color: var(--CTA);
  width: 48px !important;
  height: 48px !important;
  font-family: Inter, sans-serif;
  font-size: 36px !important;
  font-weight: 200 !important;
  line-height: 42px !important;
}

a.leaflet-control-zoom-in:hover, a.leaflet-control-zoom-out:hover {
  background: var(--white);
}

a.leaflet-control-zoom-in:active, a.leaflet-control-zoom-out:active {
  background: var(--CTA-secondary-pressed);
}

.capex-info {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  font-variant-numeric: tabular-nums;
  word-spacing: 8px;
  cursor: inherit;

  padding: 6px 12px;
  height: 36px;
  border-radius: 4px;

  color: var(--grey-8) !important;
  opacity: 0.7;
  /* Tertiary Shadow */
  filter: drop-shadow(0 18px 39px rgba(0, 0, 0, 0.07)) drop-shadow(0 5.42647px 11.7574px rgba(0, 0, 0, 0.0456112)) drop-shadow(0 2.25388px 4.88341px rgba(0, 0, 0, 0.035)) drop-shadow(0 0.815184px 1.76623px rgba(0, 0, 0, 0.0243888));

  transform: translateX(-72px) translateY(118px);
}

.capex-info svg {
  margin-top: 3px;
  margin-right: 10px;
  transform: translateY(5px);
}

.capex-info-row {
  flex-flow: row nowrap;
}

.capex-info-icon {
  display: inline-block;
}

.capex-info-label {
  margin-right: 3px;
}

.leaflet-right .leaflet-control {
  margin-right: 20px;
}

.leaflet-left .leaflet-control {
  margin-left: 20px;
}

.leaflet-bottom .leaflet-control {
  margin-bottom: 20px;
}

.polyline-measure-tooltip, .polyline-measure-popupTooltip {
  padding: 3px;
  opacity: var(--ruler-label-opacity);
  font-size: var(--ruler-text-size);
  line-height: var(--ruler-text-size);
}

.polyline-measure-popupTooltip {
  opacity: 1.0;
}

.polyline-measure-tooltip {
  display: var(--ruler-intermediate-tooltip-display);
}

.polyline-measure-tooltip .polyline-measure-tooltip-bearings-in,
.polyline-measure-tooltip .polyline-measure-tooltip-bearings-out {
  display: var(--polyline-measure-tooltip-bearings);
}

.polyline-measure-tooltip-end .polyline-measure-tooltip-bearings-out {
  display: none;
}

.polyline-measure-tooltip .polyline-measure-tooltip-difference,
.polyline-measure-tooltip .polyline-measure-tooltip-total {
  display: var(--polyline-measure-tooltip-distance);
}

.polyline-measure-tooltip-end .polyline-measure-tooltip-total {
  display: block;
}

.polyline-measure-tooltip-start .polyline-measure-tooltip-bearings-in,
.polyline-measure-tooltip-start .polyline-measure-tooltip-difference {
  display: none;
}

.polyline-measure-tooltip-end {
  display: inherit;
}

.leaflet-bar:has(#polyline-measure-control), .leaflet-control-scale-line {
  user-select: none;
}
