.capex-editfield {
  flex: 1;
}

.capex-editfield.ms-TextField .ms-TextField-wrapper {
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  gap: 12px;
  isolation: isolate;

  height: 48px;

  background: var(--white);
  border: 1px solid var(--grey-3);
  border-radius: 4px;

  margin: 10px 0;

  flex: 1;
}

.capex-editfield.ms-TextField .ms-TextField-fieldGroup {
  flex: 1;
  border: 0;
  outline: 0;
}
.capex-editfield.ms-TextField .ms-TextField-fieldGroup::after {
  border: 0;
  outline: 0;
}

.capex-editfield.ms-TextField .ms-TextField-field {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 450;
  font-size: 15px;
  line-height: 24px;

  background: var(--white);
  color: var(--grey-9);
  font-variation-settings: 'slnt' 0;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 1;
  z-index: 1;

  border: 0 !important;
  outline: 0;

  background: var(--white);
}

.capex-editfield.ms-TextField.is-active .ms-TextField-wrapper {
  background: var(--white);
  border: 1.5px solid var(--CTA);
  color: var(--CTA);
}

.capex-editfield--readOnly .ms-TextField-wrapper {
  border: 1px solid var(--grey-2);
  color: var(--grey-2);
}

.capex-editfield--readOnly .ms-TextField-field {
  color: var(--grey-5) !important;
}

.capex-editfield.ms-TextField:hover:not(:focus-within) .ms-TextField-wrapper {
  border: 1px solid var(--grey-5);
  color: var(--grey-5);
}

.capex-editfield--upperCase input {
  text-transform: uppercase;
}

.capex-editfield--upperCase input::placeholder {
  text-transform: none;
}
